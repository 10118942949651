/* AonoLabs.css */
.app-container {
  background-color: #000000;
  color: white;
  font-family: "Inter", sans-serif;
}

body {
  background-color: #000000; /* Match background with the container */
}

/* Header Title Styling */
.header-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 60px; /* Adjust size as needed */
  margin-bottom: 1rem;
  background: linear-gradient(
    145deg,
    #3f00b3,
    #c69bf7,
    #7827e6,
    #ffffff
  ); /* Indigo gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center; /* Optional: center the text */
}

.header-subtitle {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 37px; /* Adjust size as needed */
  margin-bottom: 1rem;
  background: linear-gradient(
    145deg,
    #ffffff,
    #c69bf7,
    #d6c3f1,
    #ffffff
  ); /* Indigo gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center; /* Optional: center the text */
}

/* Subheader Styling */
.subheader {
  font-family: "Inter", sans-serif;
  font-weight: 550;
  font-size: 28px;
  margin-bottom: 1rem;
  text-align: center; /* Optional: center the text */
}
@media (max-width: 768px) {
  .subheader {
    font-size: 20px !important;
  }
  .header-title {
    font-size: 40px;
  }

  .header-subtitle{
    font-size: 1.8rem;
  }
}


/* Features Section Layout */
.features {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping when space is limited */
  gap: 2rem;
  justify-content: center;
  padding: 2rem 1rem;
}

/* Feature Card Styling */
.feature-card {
  background: #171717;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  padding: 2rem;
  width: 300px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(12px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Ensure column layout for the content */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem; /* Space between elements */
}

.feature-card:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.6);
}

/* Icon Styling */
.feature-card-icon {
  width: 100px;
  height: 100px;
}

/* Rounded Title Card */
.rounded-title-card {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  padding: 0.5rem 1rem;
  display: inline-block;

  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.rounded-title-text {
  background: linear-gradient(
    145deg,
    #ffffff,
    #f79b9b,
    #aa4ff5,
    #6c4ba8
  ); /* Indigo gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rounded-title-card:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

/* Feature Card Description */
.feature-card-description {
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
}

/* Form Wrapper Styling */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Space between input and button */
  margin-top: 2rem;
  margin: 0 auto;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

/* Email Input Field */
.input-email {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 300px;
  max-width: 90%; /* Make input responsive */
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

/* Focus State */
.input-email:focus {
  border: 1px solid #5b00e4;
  box-shadow: 0 0 8px rgba(91, 0, 228, 0.5);
}

/* Primary Button Styling */
.btn-primary {
  background: linear-gradient(145deg, #b77df8, #3f00b3);
  color: white;
  width: fit-content;
  max-width: 90%; /* Make button responsive */
  border-radius: 10px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  box-shadow: 0 8px 20px rgba(91, 0, 228, 0.3);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Hover Effect */
.btn-primary:hover {
  background: linear-gradient(145deg, #9c6eff, #5b00e4);
  color: #000;
  transform: scale(1.05);
  box-shadow: 0 12px 30px rgba(91, 0, 228, 0.5);
}

/* Focus/Active State */
.btn-primary:active {
  transform: scale(0.98);
  box-shadow: 0 5px 15px rgba(91, 0, 228, 0.4);
}

/* Responsive Layout for Smaller Screens */
@media (max-width: 768px) {
  .form-container {
    width: 90%; /* Ensure form takes most of the mobile screen */
    gap: 1rem; /* Maintain spacing between input and button */
  }

  .input-email {
    width: 50%; /* Full width for mobile */
  }
  .btn-primary {
    font-size: 1rem; /* Adjust font size for smaller screens */
    padding: 0.75rem; /* Smaller padding for mobile */
  }
}




/* Main Container */
.job-listings-carousel {
  padding: 20px;
  border-radius: 24px;
  margin: 0 auto;
  width: 70%;
  overflow: hidden; /* Prevent content overflow */
  position: relative;
}

.job-listings-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 37px;
  text-align: center;
  margin-bottom: 1rem;
  background: linear-gradient(145deg, #ffffff, #c69bf7, #d6c3f1, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


/* Carousel Settings */
.slick-list {
  overflow: hidden; /* Ensure slides don't spill out */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 0;
}

.slick-slide {
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0.5;
  transform: scale(0.8); /* Non-centered slides zoomed out */
  overflow: hidden; /* Prevent content overflow */
}

.slick-center {
  opacity: 1;
  transform: scale(1); /* Focus effect on centered slide */
}

.job-card-carousel {
  background: #1b1b1b;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  padding: 1.5rem;
  margin: 0 auto;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  width: 100%; /* Ensure the card fits inside its parent */
  box-sizing: border-box;
}

/* Job Card Content */
.job-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.job-location,
.job-type {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0.25rem 0;
}

.job-description {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  margin-top: 0.5rem;
}

/* Button */
.apply-button {
  margin-top: 1rem;
  background: linear-gradient(145deg, #b77df8, #3f00b3);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 8px 20px rgba(91, 0, 228, 0.3);
}

.apply-button:hover {
  background: linear-gradient(145deg, #9c6eff, #5b00e4);
  color: black;
  transform: scale(1.05);
  box-shadow: 0 12px 30px rgba(91, 0, 228, 0.5);
}

/* Responsive Tweaks */
@media (max-width: 767px) {
  .job-listings-carousel {
    width: 80%;
  }

  .job-card-carousel {
    margin: 0 auto;
    padding: 1rem;
  }

  .job-title {
    font-size: 1.5rem;
  }

  .job-location,
  .job-type {
    font-size: 0.9rem;
  }

  .apply-button {
    font-size: 0.9rem;
    padding: 0.5rem 1.5rem;
  }

}

@media (max-width: 500px) {
  .job-listings-carousel {
    width: 80%;
  }

  .job-card-carousel {
    padding: 0.75rem;
  }

  .job-title {
    font-size: 1.3rem;
  }

  .job-location,
  .job-type {
    font-size: 0.9rem;
  }

  .apply-button {
    font-size: 0.85rem;
    padding: 0.4rem 1.2rem;
  }
  .job-description {
    font-size: 0.87rem;
  }
}

@media (max-width: 380px) {
  .job-listings-carousel {
    width: 95%;
  }

  .job-card-carousel {
    padding: 0.5rem;
  }

  .job-title {
    font-size: 1.1rem;
  }

  .job-location,
  .job-type {
    font-size: 0.75rem;
  }

  .apply-button {
    font-size: 0.75rem;
    padding: 0.3rem 1rem;
  }
  .job-description {
    font-size: 0.7rem;
  }
}